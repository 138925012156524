<template>
    <section>
        <modal ref="modalJustificacion" titulo="Justificacion" tamano="modal-lg">
            <div class="row mx-0 mb-4">
                <div class="col-3">
                    <p class="f-22 f-600" :style=" estado == 'Aprobado' ? 'color:#0892DD;' : estado == 'Denegado' ? 'color:#FF612B;' : '' ">
                        {{ estado }}
                    </p>
                </div>
                <div class="col-4 d-middle-center ml-auto">
                    Martes 18 de Jun. 2020
                </div>
            </div>
            <div class="row mx-0">
                <div class="col-12">
                    <p class="text-muted2">
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Porro ut quae tempora. Earum, sequi odit at voluptatem possimus obcaecati eum nihil maiores perspiciatis! Ab aliquam, praesentium excepturi ea ullam fuga.
                    </p>
                </div>
            </div>
        </modal>
    </section>
</template>
<script>
export default {
    data(){
        return{
            estado: ''
        }
    },
    methods: {
        toggle(estado){
            this.$refs.modalJustificacion.toggle();
            this.estado = estado
        }
    }
}
</script>
